import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Form, Table } from 'antd';
import Markdown from 'react-markdown';
import PropTypes from 'prop-types';

import AccountChooser from './AccountChooser';
import { ApiContext } from '../services/HubApi';

// eslint-disable-next-line no-undef
var Url = require('url-parse');

function SolutionData()
{
    //const api = useContext(ApiContext);
    //const [ resources, setResources ] = useState([]);
    const resources = [];

    const onLoad = useCallback(async () => {
    //    try {
    //        let metricsData = await api.getMetrics(props.appState.accountID, props.solution.solutionName);
    //        setResources(metricsData.resources);
    //    }
    //    catch (error) {
    //        console.error(error);
    //        //alert(error);
    //    }
    }, []);

    useEffect(() => {
        onLoad();
    }, [onLoad]);

    const columns = [
        { title: 'Service Name', dataIndex: 'resourceId', key: 'resourceId' },
        { title: 'Running Since', dataIndex: 'utcStartTime', key: 'utcStartTime' },
    ];

    return (<Table
        pagination={false}
        rowKey='resourceId'
        columns={columns}
        dataSource={resources}
    />);
}

SolutionData.propTypes = {
    appState: PropTypes.shape({
        accountName: PropTypes.string,
        accountID: PropTypes.string
    }),
    solution: PropTypes.shape({
        solutionName: PropTypes.string
    })
};

function SolutionIntroduction(props)
{
    const [ contents, setContents ] = useState('Loading...');

    const onLoad = useCallback(async () => {
        var val = props.solution.introduction;

        val = val.replace(/\\n/g, '\n');
        setContents(val);
    }, [props]);

    useEffect(() => {
        onLoad();
    }, [onLoad]);

    return (<Markdown source={contents} />);
}

SolutionIntroduction.propTypes = {
    solution: PropTypes.shape({
        introduction: PropTypes.string
    })
};

export default function SolutionHome(props) {
    const api = useContext(ApiContext);

    const [accountID, setAccountID] = useState(props.appState.accountID);

    const [ solutions, setSolutions ] = useState([]);

    const onLoad = useCallback(async () => {

        async function loadSolutionIds() {
            try {
                const solutionArray = await api.getSolutions();

                const solutionObjects = solutionArray.reduce((arr, obj) => {
                    arr.push({ key: obj, loading: true });

                    return arr;
                }, []);

                return solutionObjects;
            }
            catch (error) {
                console.error(error);
                //alert(error);
            }
        }

        async function loadSolutionData(id) {
            try {
                let solutionData = await api.getSolution(id);
                solutionData['key'] = id;
                solutionData['loading'] = false;

                let entitlements = await api.getEntitlementsForSolution(accountID, id);
                solutionData['entitlements'] = entitlements;

                return solutionData;
            }
            catch (error) {
                console.error(error);
                //alert(error);
            }
        }

        let solutionIds = await loadSolutionIds();
        let newSolutions = [];

        for (let i = 0; i < (solutionIds ? solutionIds.length : 0); ++i) {
            const solutionId = solutionIds[i].key;
            newSolutions[i] = await loadSolutionData(solutionId);
        }

        setSolutions(newSolutions);
    }, [api, accountID]);

    useEffect(() => {
        onLoad();
    }, [onLoad]);

    useEffect(() => {
        return ()  => {
            //console.log("Solutions cleanup")
        };
    }, []);

    async function setAccountDetails(id) {
        setAccountID(id);
    }

    const page = [];

    if (['mkOps', 'mkSales'].includes(props.appState.userRole)) {
        const formItemLayout =
        {
            labelCol: { span: 4 },
            wrapperCol: { span: 14 },
        };

        page.push(
            <Form {...formItemLayout} key="acc" className='choose-account-form'>
                <Form.Item label="Choose account">
                    <AccountChooser accountID={accountID} setAccountDetails={setAccountDetails}/>
                </Form.Item>
            </Form>
        );
    }

    const renderLogo = (...[, row, ]) => {
        const logoURL = process.env.PUBLIC_URL + '/logos/' + row.solutionName + '.png';
        return {
            children: <img width="100%" alt="Solution logo" src={logoURL}/>
        };
    };

    const renderLink = (...[, row, ]) => {
        let hubURL = row.hubURL;
        if (hubURL === 'https://aquila.aas.mediakind.com') {
            // Almost definitely true
            let url = new Url(window.location.href, true);
            if (url.hostname.match(/preprod/) !== null) {
                // 50% true
                hubURL = 'https://aquila-preprod.aas.mediakind.com';
            }
        }
        if (row['entitlements'].length > 0) {
            return {
                children: <Button href={hubURL}>Solution Hub</Button>
            };
        }

        return {
            children: <p>You have no active entitlement for this solution.</p>
        };
    };

    const renderContents = (...[, row, ]) => {
        if (row['entitlements'].length > 0) {
            return {
                children: <SolutionData appState={props.appState} solution={row}/>
            };
        }

        return {
            children: <SolutionIntroduction appState={props.appState} solution={row}/>
        };
    };

    const columns = [
        { title: 'Logo',     dataIndex: 'solutionName', key: 'logo', render: renderLogo },
        { title: 'Link',     dataIndex: 'entitlements', key: 'link', render: renderLink },
        { title: 'Contents', dataIndex: 'entitlements', key: 'contents', width: '60%', render: renderContents },
    ];

    page.push(
        <Table key="table" showHeader={false} pagination={false} rowKey="solutionName" columns={columns} dataSource={solutions}/>
    );
    
    return (
        <>
            {page}
        </>
    );
}

SolutionHome.propTypes = {
    appState: PropTypes.shape({
        accountName: PropTypes.string,
        accountID: PropTypes.string,
        userRole: PropTypes.string,
    })
};
