import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, Icon, Input, Alert } from 'antd';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';

import { ApiContext } from '../services/HubApi';

// Cookie of account name set by solution hub when switching accounts.
// When set, fill the account name on the login page with the cookie value.
// The cookie name must be kept in sync with the cookie name in aquila frontend.
const FIRST_SELECTED_ACCOUNT_COOKIE = '__Host_first_selected-account';

export default function Login(props) {
    // Input field data
    const [accountName, setAccountName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showAlert, setShowAlert] = useState(false);


    // State data
    const [isLoading, setIsLoading] = useState(false);

    const api = useContext(ApiContext);

    // Readt selected account cookie once and save in state variable.
    useEffect(() => {
        const selected_account = Cookies.get(FIRST_SELECTED_ACCOUNT_COOKIE);
        if (selected_account !== undefined) {
            const accountParts = selected_account.split(':::');
            if (accountParts.length === 2 && accountParts[0].length > 0 && accountParts[1].length > 0) {
                setAccountName(accountParts[0]);
            }
        }
    }, []);

    async function onSubmit(event) {
        event.preventDefault();

        if (event.nativeEvent.submitter.name === 'login-msal') {
            try {
                await api.loginMSAL(accountName);
                return;
            }
            catch (e) {
                alert(e.message);
            }
        }

        setIsLoading(true);

        try {
            const user = await api.login(accountName, userEmail, password);

            setIsLoading(false);

            if (user) {
                setShowAlert(false);
                props.appStateChange({
                    ...props.appState,
                    hasAuthenticated: true,
                    validationRequired: (user.password === 'Validation Required'),
                    accountID: user.accountID,
                    accountName: user.accountName,
                    userEmail: user.userEmail,
                    userName: user.userName,
                    userRole: user.role
                });
            } else {
                setShowAlert(true);
            }
        }
        catch (e) {
            alert(e.message);
            setIsLoading(false);
        }
    }

    function validate() {
        return accountName.length > 0 && userEmail.length > 0 && password.length > 0;
    }

    const alertStyle = {
        display: showAlert ? null : 'none',
        paddingTop: '20px'
    };

    return (
        <Form onSubmit={onSubmit} className='login-form'>
            <Form.Item>
                <Input
                    onChange={e => setAccountName(e.target.value)}
                    prefix={<Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder="Customer Account"
                    className='hook-login-account-name'
                    value={accountName}
                />
            </Form.Item>
            <Form.Item>
                <Input
                    onChange={e => setUserEmail(e.target.value)}
                    prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder="User Email"
                    className='hook-login-user-email'
                />
            </Form.Item>
            <Form.Item>
                <Input
                    onChange={e => setPassword(e.target.value)}
                    prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    type="password"
                    placeholder="Password"
                    className='hook-login-password'
                />
            </Form.Item>
            <Form.Item>
                <Button name="login-msal" block className='login-form-button' htmlType='submit' loading={isLoading} type='primary' >Log in with Microsoft account</Button>
            </Form.Item>
            <Form.Item>
                <Button name="login" block className='login-form-button' disabled={!validate()} htmlType='submit' loading={isLoading} type='primary' >Log In</Button>
            </Form.Item>
            <Alert style={alertStyle} message='Login Error' description="Invalid combination of Account, Email and Password" type="error" showIcon/>
        </Form>
    );
}

Login.propTypes = {
    appStateChange: PropTypes.func,
    appState: PropTypes.object
};