import React from 'react';
import { Row, Col, Layout } from 'antd';
const { Footer } = Layout;

export default function MKFooter() {

    return (
        <Footer>
            <Row className="footer-links" justify="space-around">
                <Col xs={12} sm={8} md={6} lg={3}><a target="_blank" rel="noopener noreferrer" href="https://www.mediakind.com/cookie-policy/">Cookie Policy</a></Col>
                <Col xs={12} sm={8} md={6} lg={3}><a target="_blank" rel="noopener noreferrer" href="https://www.mediakind.com/privacy-policy/">Privacy Policy</a></Col>
                <Col xs={12} sm={8} md={6} lg={4}><a target="_blank" rel="noopener noreferrer" href="https://www.mediakind.com/Code-of-Business-Ethics.pdf">Code of Business Ethics</a></Col>
                <Col xs={12} sm={8} md={6} lg={3}><a target="_blank" rel="noopener noreferrer" href="https://www.mediakind.com/modern-slavery-and-human-trafficking-statement-2018/">Modern Slavery</a></Col>
                <Col xs={12} sm={8} md={8} lg={3}><a target="_blank" rel="noopener noreferrer" href="https://www.mediakind.com/email-disclaimer/">Email Disclaimer</a></Col>
                <Col xs={12} sm={8} md={8} lg={4}><a target="_blank" rel="noopener noreferrer" href="https://www.mediakind.com/social-media-usage-policy/">Social Media Usage Policy</a></Col>
                <Col xs={12} sm={8} md={8} lg={3}><a target="_blank" rel="noopener noreferrer" href="https://www.mediakind.com/terms-of-use/">Terms of use</a></Col>
            </Row>
            <Row justify="space-around">
                <Col sm={24}><p className="text-center">Copyright MediaKind, 2021, All Rights Reserved.</p></Col>
            </Row>
        </Footer>
    );
}