import React, { useContext, useEffect, useState } from 'react';
import { Select } from 'antd';
import PropTypes from 'prop-types';

import { ApiContext } from '../services/HubApi';

const { Option } = Select;

export default function SolutionChooser(props) {
    const api = useContext(ApiContext);

    const [ solutions, setSolutions ] = useState([]);

    useEffect(() => {
        async function loadSolutions() {
            try {
                const solutionArray = await api.getSolutions();
                return solutionArray;
            }
            catch (error) {
                return [];
            }
        }

        async function onLoad() {
            let solutionIds = await loadSolutions();
            setSolutions(solutionIds);
        }

        onLoad();

    }, [api]);

    useEffect(() => {
        return ()  => {
            //console.log("SolutionChooser cleanup")
        };
    }, []);

    async function onChange(value) {
        props.setSolutionName(value);
    }

    const options = [];
    for (let i = 0; i < solutions.length; ++i) {
        options.push(<Option key={solutions[i]}>{solutions[i]}</Option>);
    }

    return (
        <Select value={props.solutionName} onChange={onChange} disabled={props.disabled}>
            {options}
        </Select>
    );

}

SolutionChooser.propTypes = {
    setSolutionName: PropTypes.func,
    solutionName: PropTypes.string,
    disabled: PropTypes.bool
};