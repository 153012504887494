import React from 'react';
import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';
import { Menu } from 'antd';

import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export default function MkNav(props) {
    
    const { role } = props;
    let history = useHistory();
    let location = useLocation();

    if (role === 'mkOps') {

        return (<Menu  className='nav-bar' mode='horizontal' selectedKeys={[location.pathname]}>
            <Menu.Item key='/' onClick={() => {history.push('/');}}>
                Solutions
            </Menu.Item>
            <Menu.Item key='/accounts' onClick={() => {history.push('/accounts');}} >
                Accounts
            </Menu.Item>
            <Menu.Item key='/users' onClick={() => {history.push('/users');}} > 
                Users
            </Menu.Item>
            <Menu.Item key='/entitlements' onClick={() => {history.push('/entitlements');}} >
                Entitlements
            </Menu.Item>
            <Menu.Item key='/reports' onClick={() => {history.push('/reports');}} >
                Reports
            </Menu.Item>
            <Menu.Item key='/createreport' onClick={() => {history.push('/createreport');}} >
                New Report
            </Menu.Item>
            <Menu.Item key='/documentation' onClick={() => {history.push('/documentation');}} >
                Documentation
            </Menu.Item>
            <Menu.Item key='/user' onClick={() => {history.push('/user');}} >
                <FontAwesomeIcon icon={faUser} />
            </Menu.Item>
        </Menu>);
    } else if (role === 'admin') {
        return (<Menu className='nav-bar' mode='horizontal' selectedKeys={[location.pathname]}>
            <Menu.Item key='/' onClick={() => {history.push('/');}} >
                Home
            </Menu.Item>
            <Menu.Item key='/users' onClick={() => {history.push('/users');}} > 
                Users
            </Menu.Item>
            <Menu.Item key='/entitlements' onClick={() => {history.push('/entitlements');}} >
                Entitlements
            </Menu.Item>
            <Menu.Item key='/reports' onClick={() => {history.push('/reports');}} >
                Reports
            </Menu.Item>
            <Menu.Item key='/createreport' onClick={() => {history.push('/createreport');}} >
                New Report
            </Menu.Item>
            <Menu.Item key='/documentation' onClick={() => {history.push('/documentation');}} >
                Documentation
            </Menu.Item>
            <Menu.Item key='/user' onClick={() => {history.push('/user');}} >
                <FontAwesomeIcon icon={faUser} />
            </Menu.Item>
        </Menu>);

    } else if (role === 'mkSales') {
        return (<Menu className='nav-bar' mode='horizontal' selectedKeys={[location.pathname]}>
            <Menu.Item key='/' onClick={() => {history.push('/');}}>
                Solutions
            </Menu.Item>
            <Menu.Item key='/accounts' onClick={() => {history.push('/accounts');}} >
                Accounts
            </Menu.Item>
            <Menu.Item key='/users' onClick={() => {history.push('/users');}} > 
                Users
            </Menu.Item>
            <Menu.Item key='/entitlements' onClick={() => {history.push('/entitlements');}} >
                Entitlements
            </Menu.Item>
            <Menu.Item key='/documentation' onClick={() => {history.push('/documentation');}} >
                Documentation
            </Menu.Item>
            <Menu.Item key='/user' onClick={() => {history.push('/user');}} >
                <FontAwesomeIcon icon={faUser} />
            </Menu.Item>
        </Menu>);
    } else if (role === 'user') {
        return (<Menu className='nav-bar' mode='horizontal' selectedKeys={[location.pathname]}>
            <Menu.Item key='/' onClick={() => {history.push('/');}} >
                Home
            </Menu.Item>
            <Menu.Item key='/entitlements' onClick={() => {history.push('/entitlements');}} >
                Entitlements
            </Menu.Item>
            <Menu.Item key='/reports' onClick={() => {history.push('/reports');}} >
                Reports
            </Menu.Item>
            <Menu.Item key='/documentation' onClick={() => {history.push('/documentation');}} >
                Documentation
            </Menu.Item>
            <Menu.Item key='/user' onClick={() => {history.push('/user');}} >
                <FontAwesomeIcon icon={faUser} />
            </Menu.Item>
        </Menu>);
    }
}

MkNav.propTypes = {
    role: PropTypes.string
};