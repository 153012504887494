import React, { useCallback, useEffect, useState } from 'react';
import { Tabs } from 'antd';
import Markdown from 'react-markdown';
import PropTypes from 'prop-types';

const { TabPane } = Tabs;

DocumentationTab.propTypes = {
    docUrls: PropTypes.arrayOf(PropTypes.string)
};

function DocumentationTab(props) {

    const [ markdown, setMarkdown ] = useState('');

    const onLoad = useCallback(async () => {

        let text = '';
        for (let i = 0; i < props.docUrls.length; ++i) {
            // eslint-disable-next-line no-undef
            let response = await fetch(process.env.PUBLIC_URL + props.docUrls[i]);  

            if (response.ok) {
                const t = await response.text();
                text = text + t + '\n';
            } else {
                text = text + 'Failed to load document from ' + props.docUrls[i] + '\n';
            }
        }
        setMarkdown(text);
    }, [props]);

    useEffect(() => {
        onLoad();
    }, [onLoad]);

    return (<Markdown source={markdown} />);
}

export default function Documentation(props) {

    const [ docConfig, setDocConfig ] = useState([]);

    const onLoad = useCallback(async () => {
        try {
            // eslint-disable-next-line no-undef
            let response = await fetch(process.env.PUBLIC_URL + 'doc/' + props.role + '.json');

            if (response.ok) {
                const t = await response.json();
                setDocConfig(t);
            } else {
                setDocConfig([{ name: 'Error', urls: ['doc/blank.md'] }]);
            }
        }
        catch(e) {
            setDocConfig([{ name: 'Error', urls: ['doc/blank.md'] }]);
        }
    }, [props]);

    useEffect(() => {
        onLoad();
    }, [onLoad]);

    const tabs = [];
    for (let i = 0; i < docConfig.length; ++i) {
        tabs.push(
            <TabPane tab={docConfig[i].name} key={i.toString()}>
                <DocumentationTab docUrls={docConfig[i].urls}/>
            </TabPane>
        );
    }

    return (
        <Tabs tabPosition="left" size="small">
            {tabs}
        </Tabs>
    );

}

Documentation.propTypes = {
    role: PropTypes.string
};