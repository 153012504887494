import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Form, Input, Table, Popconfirm, message, Tree } from 'antd';
import PropTypes from 'prop-types';

import { ApiContext } from '../services/HubApi';

const { Column } = Table;
const ButtonGroup = Button.Group;

function isType(value, typeName){
    return Object.prototype.toString.call(value).indexOf(typeName) > -1;
}

function isIterable(value) {
    return isType(value, 'Object') || isType(value, 'Array');
}

function buildTree(srcObject, compoundKey) {

    let items = [];

    for (let key in srcObject) {
        let itemValue = srcObject[key];

        let newItem = {
            key: key,
            title: key
        };

        items.push(newItem);

        if (isIterable(itemValue)) {
            // see if its iterable else add it as a property...
            let subItems = buildTree(itemValue, `${compoundKey}-${key}`);
            newItem.children = subItems;
        } else {
            newItem.title = `${newItem.title} : ${itemValue}`;
        }
    }

    return items;
}

function  BrowseTree(props) {

    const [showTree, setShowTree] = useState(false);

    let treeData = buildTree(props.viewSolution ? props.viewSolution : {}, '');

    return showTree ? <Tree key='tree' defaultExpandAll={true} showLine={true} showIcon={true} treeData={treeData}/>
        : <Button onClick={() => {setShowTree(true);}}>Click to Show</Button>;
}

BrowseTree.propTypes = {
    viewSolution: PropTypes.object
};

function EditSolution(props) {

    const { contextSolution = null } = props;

    const api = useContext(ApiContext);
    const [emptyState] = useState({ solutionName: '', description: '', hubURL: '', quotas: [] });
    const [formData, setFormData] = useState(emptyState);
    const [newQuotaData, setNewQuotaData] = useState('');
    const [isUpdating, setIsUpdating] = useState(false);

    const [mode, setMode] = useState('Add');

    useEffect(() => {
        if (props.mode === 'Add') {
            setMode(props.mode);
            setFormData(emptyState);
        }
        if (props.mode === 'Edit') {
            setMode(props.mode);
            setFormData(props.editting);
        }
        if (props.mode === 'View') {
            setMode(props.mode);
            setFormData(props.editting);
        }
    }, [props, emptyState]);

    useEffect(() => {
        return ()  => {
            //console.log("EditSolution cleanup")
        };
    }, []);

    async function onSubmit(event) {
        event.preventDefault();

        setIsUpdating(true);

        try {
            if (props.mode === 'Add') {
                await api.createSolution(formData.solutionName, formData.description, formData.hubURL, formData.quotas);
            
                message.success('New solution created');
            } else if (props.mode === 'Edit') {
                await api.editSolution(formData.solutionName, formData.description, formData.hubURL, formData.quotas);
            
                message.success('Solution edited');
            }
            
        }
        catch (error) {
            alert(error);
        }

        setIsUpdating(false);
        setFormData(emptyState);
        await props.reList();
    }

    async function onCancel(event) {
        event.preventDefault();
        setIsUpdating(false);
        setFormData(emptyState);
        setMode('Add');
    }

    async function onChange(event) {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    }

    async function onQuotaChange(event) {
        const { value } = event.target;
        setNewQuotaData(value);
    }

    async function onDeleteQuota(id, event) {
        event.preventDefault();

        try {
            let newQuotas = formData.quotas.filter(op => op !== id);
            setFormData({ ...formData, quotas: newQuotas });
        } catch (error) {
            alert(error);
            console.error(error);
        }
    }

    async function onAddQuota(event) {
        event.preventDefault();

        try {
            let newQuotas = formData.quotas.filter(op => op !== newQuotaData);
            newQuotas.push(newQuotaData);

            setFormData({ ...formData, quotas: newQuotas });
            setNewQuotaData('');
        } catch (error) {
            alert(error);
            console.error(error);
        }
    }

    function validateQuota() {
        return newQuotaData.length > 0;
    }

    function validate() {
        return formData.solutionName.length > 0 && formData.description.length  && formData.hubURL.length > 0;
    }

    const formItemLayout =
    {
        labelCol: { span: 4 },
        wrapperCol: { span: 14 },
    };

    const buttonItemLayout = {
        wrapperCol: { span: 14, offset: 4 },
    };

    const newQMLayout = {
        labelCol: { span: 5 },
        wrapperCol: { span: 11 },
    };

    return (
        <div>
            <h3>{mode} Solution</h3>
            <Form onSubmit={onSubmit} {...formItemLayout} className='add-solution-form'>
                <Form.Item label="Solution Name">
                    <Input
                        disabled={mode === 'Edit'}
                        name="solutionName"
                        onChange={onChange}
                        placeholder="Unique solution name"
                        value={formData.solutionName}
                    />
                </Form.Item>
                <Form.Item label="Description">
                    <Input
                        name="description"
                        onChange={onChange}
                        placeholder="Description of solution"
                        value={formData.description}
                    />
                </Form.Item>
                <Form.Item label="Solution Hub URL">
                    <Input
                        name="hubURL"
                        onChange={onChange}
                        placeholder="Base URL including http(s)://"
                        value={formData.hubURL}
                    />
                </Form.Item>
                <Form.Item label="Quotas">
                    <Table
                        dataSource={formData.quotas}
                        rowKey={record => record}
                    >
                        <Column title="Name" dataIndex='' render={(text, record) => record}/>
                        <Column title="Action" dataindex=''
                            render={(text, record) => (
                                (mode !== 'View') ?
                                    <div>
                                        <Button
                                            onClick={(event) => { onDeleteQuota(record, event); }}
                                        >delete
                                        </Button>
                                    </div>
                                    :
                                    <div/>
                            )}
                        />
                    </Table>
                    {(mode !== 'View') ?
                        <Form.Item label="New Quota"  {...newQMLayout} >
                            <Input name="name" placeholder="Quota Name" onChange={onQuotaChange} value={newQuotaData}/>
                            <Button type='primary' onClick={(event) => { onAddQuota(event);}} disabled={!validateQuota()}>Add Quota</Button>
                        </Form.Item>
                        : <div />}
                </Form.Item>
                <Form.Item label="Solution Properties">
                    <BrowseTree viewSolution={contextSolution}/>
                </Form.Item>
                <Form.Item>
                    { (mode !== 'View') ?
                        <Form.Item {...buttonItemLayout}>
                            <ButtonGroup>
                                <Button onClick={onCancel}>Cancel</Button>
                                <Button type='primary' htmlType='submit' disabled={!validate()} loading={isUpdating} >
                                    {mode === 'Add' ? 'Add Solution' : 'Save changes'} 
                                </Button>
                            </ButtonGroup>
                        </Form.Item>
                        : <div />
                    }
                </Form.Item>
            </Form>
        </div>
    );
}

const SolutionEditShape = {
    valid: PropTypes.bool,
    solutionName: PropTypes.string,
    description: PropTypes.string,
    hubURL: PropTypes.string,
    quotas: PropTypes.arrayOf(PropTypes.string)
};


EditSolution.propTypes = {
    contextSolution: PropTypes.object,
    mode: PropTypes.string,
    editting: PropTypes.shape(SolutionEditShape),
    reList: PropTypes.func
};

export default function SolutionMgmt(props) {
    const api = useContext(ApiContext);

    const [ solutions, setSolutions ] = useState([]);
    const [ isLoading, setLoading ] = useState(false);
    const [ isDeleting, setDeleting ] = useState(false);

    const [emptyState] = useState({ valid: false, solutionName: '', description: '', hubURL: '', quotas: [] });
    const [ editting, setEditting ] = useState(emptyState);
    const [ contextSolution, setContextSolution ] = useState(null);

    const onLoad = useCallback(async () => {
        async function loadSolutionIds() {
            try {
                const solutionArray = await api.getSolutions();

                const solutionObjects = solutionArray.reduce((arr, obj) => {
                    arr.push({ key: obj, loading: true });

                    return arr;
                }, []);

                return solutionObjects;
            }
            catch (error) {
                console.error(error);
                //alert(error);
            }
        }

        async function loadSolutionData(id) {
            try {
                let solutionData = await api.getSolution(id);
                solutionData['key'] = id;
                solutionData['loading'] = false;

                return solutionData;
            }
            catch (error) {
                console.error(error);
                //alert(error);
            }
        }

        setLoading(true);

        let solutionIds = await loadSolutionIds();

        let newSolutions = [];

        for (let i = 0; i < (solutionIds ? solutionIds.length : 0); ++i) {
            const solutionId = solutionIds[i].key;
            newSolutions[i] = await loadSolutionData(solutionId);
        }

        setSolutions(newSolutions);
        setEditting(emptyState);
        setContextSolution(null);

        setLoading(false);
    }, [api, emptyState]);

    useEffect(() => {
        onLoad();
    }, [onLoad]);

    useEffect(() => {
        return ()  => {
            //console.log("SolutionList cleanup")
        };
    }, []);

    async function reList() {
        // Used when the solutions are added/editted
        //onLoad()
        // Trigger a redraw from the top-level
        await props.appStateChange({ ...props.appState, redrawEvent: props.appState.redrawEvent + 1 });
    }

    async function onDelete(id, event) {
        event.preventDefault();

        setDeleting(true);

        try {
            await api.deleteSolution(id);

            let newSolutions = solutions.filter(op => op.key !== id);
            setSolutions(newSolutions);

            message.success('Solution deleted');

        } catch (error) {
            alert(error);
            console.error(error);
        }

        setContextSolution(null);
        setDeleting(false);
    }

    async function onEdit(id, event) {
        event.preventDefault();

        for (let i = 0; i < solutions.length; ++i) {
            if (solutions[i].key === id)
            {
                setContextSolution(solutions[i]);
                //console.log(solutions[i])
                setEditting({
                    valid: true,
                    solutionName: solutions[i].solutionName,
                    description: solutions[i].description,
                    hubURL: solutions[i].hubURL,
                    quotas: solutions[i].quotas,
                });
            }
        }
    }

    return (
        <div>
            <Table
                bordered
                dataSource={solutions}
                loading={isLoading}
                rowKey={record => record.key}
            >
                <Column title='Name' dataIndex='key' />
                <Column title='Description' dataIndex='description' />
                <Column title='Action' dataIndex=''
                    render={(text, record) => (
                        (props.readOnly) ?
                            <Button onClick={(event) => { onEdit(record.key, event); }}> Details </Button>
                            :
                            <div>
                                <Popconfirm
                                    title="Really delete this solution?"
                                    onConfirm={(event) => { onDelete(record.key, event); }}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button loading={isDeleting}>Delete</Button>
                                </Popconfirm>
                                <Button
                                    onClick={(event) => { onEdit(record.key, event); }}
                                >
                                Edit
                                </Button>
                            </div>
                    )}
                />
            </Table>
            { editting.valid ?
                props.readOnly ?
                    <EditSolution mode="View" reList={reList} editting={editting} contextSolution={contextSolution}/>
                    :   <EditSolution mode="Edit" reList={reList} editting={editting} contextSolution={contextSolution}/>
                :  props.readOnly ?
                    <div/>
                    :   <EditSolution mode="Add"  reList={reList} contextSolution={contextSolution}/>
            }
        </div>
    );
}

SolutionMgmt.propTypes = {
    readOnly: PropTypes.bool,
    appStateChange: PropTypes.func,
    appState: PropTypes.object
};
