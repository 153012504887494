import React from 'react';
import { Layout } from 'antd';
import PropTypes from 'prop-types';
const { Header } = Layout;

export default function MKFooter(props) {
    const svgUrl = process.env.PUBLIC_URL + '/logo-color.svg';

    const data = { message: 'SaaS Management Interface' };

    if (props.appState !== undefined) {
        data.message = props.appState.accountName + ' SaaS Management - Hello ' + props.appState.userName;
    }

    return (
        <Header>
            <div>
                <img src={svgUrl} height="48" alt="Company Logo"/>
                <div className="mk-header-data">{data.message}</div>
            </div>
        </Header>
    );
}

MKFooter.propTypes = {
    appState: PropTypes.shape({
        accountName: PropTypes.string,
        userName: PropTypes.string
    })
};