import React, { useContext, useEffect, useState } from 'react';
import { Select } from 'antd';
import PropTypes from 'prop-types';

import { ApiContext } from '../services/HubApi';

const { Option } = Select;

export default function RoleChooser(props) {
    const api = useContext(ApiContext);

    const [ roles, setRoles ] = useState([]);

    useEffect(() => {
        async function loadRoles() {
            try {
                const roleArray = await api.getRoles();
                return roleArray;
            }
            catch (error) {
                return [];
            }
        }

        async function onLoad() {
            let roleIds = await loadRoles();
            setRoles(roleIds);
        }

        onLoad();

    }, [api]);

    useEffect(() => {
        return ()  => {
            //console.log("RoleChooser cleanup")
        };
    }, []);

    async function onChange(value) {
        props.setRole(value);
    }

    const options = [];
    for (let i = 0; i < roles.length; ++i) {
        options.push(<Option key={roles[i]}>{roles[i]}</Option>);
    }

    return (
        <Select value={props.role} onChange={onChange} disabled={props.disabled}>
            {options}
        </Select>
    );

}

RoleChooser.propTypes = {
    setRole: PropTypes.func,
    role: PropTypes.string,
    disabled: PropTypes.bool
};