import React, { useContext, useState } from 'react';
import { message, Alert, Button, Form, Icon, Input } from 'antd';
import PropTypes from 'prop-types';

import { ApiContext } from '../services/HubApi';

const ButtonGroup = Button.Group;

export default function User(props) {

    const api = useContext(ApiContext);

    const emptyState = {
        visible: props.appState.validationRequired,
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
    };
    const [formData, setFormData] = useState(emptyState);
    const [isUpdating, setIsUpdating] = useState(false);

    async function onSubmit(event) {
        event.preventDefault();

        if (!formData.visible) {
            setFormData({ ...formData, visible: true });

            console.log(props.appState);
        }
        else {
            setIsUpdating(true);
            try {
                await api.password(formData.oldPassword, formData.newPassword);

                message.success('Password changed');
            }
            catch (error) {
                alert(error);
            }
            setIsUpdating(false);
            setFormData({ ...formData, visible: false });
        }
    }
    

    async function onLogout() {
        api.logout();
        window.location.href = '/';
    }

    function validate() {
        if (!formData.visible) {return true;}

        if (formData.newPassword === '') {return false;}

        if (formData.newPassword !== formData.confirmPassword) { return false; }

        return true;
    }

    async function onChange(event) {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    }

    const formItemLayout =
    {
        labelCol: { span: 4 },
        wrapperCol: { span: 14 },
    };

    const buttonItemLayout = {
        wrapperCol: { span: 14, offset: 4 },
    };

    const formItems = [];

    formItems.push(
        <Form.Item key="account" label="Customer Account">
            <Input
                disabled={true}
                value={props.appState.accountName}
                prefix={<Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />}
            />
        </Form.Item>
    );

    formItems.push(
        <Form.Item key="name" label="User Name">
            <Input
                disabled={true}
                value={props.appState.userName}
                prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
            />
        </Form.Item>
    );

    formItems.push(
        <Form.Item key="role" label="Role">
            <Input
                disabled={true}
                value={props.appState.userRole}
                prefix={<Icon type="crown" style={{ color: 'rgba(0,0,0,.25)' }} />}
            />
        </Form.Item>
    );

    if (formData.visible) {
        if (props.appState.validationRequired) {
            formItems.push(
                <Alert
                    key="validation"
                    type="warning"
                    closable
                    showIcon
                    message="You must enter a password to finish validating your account"
                />
            );
        } else {
            formItems.push(
                <Form.Item key="current" label="Current Password">
                    <Input
                        value={formData.oldPassword}
                        name="oldPassword"
                        type="password"
                        onChange={onChange}
                    />
                </Form.Item>
            );
        }

        formItems.push(
            <Form.Item key="new1" label="New Password">
                <Input
                    value={formData.newPassword}
                    name="newPassword"
                    type="password"
                    onChange={onChange}
                />
            </Form.Item>
        );
        formItems.push(
            <Form.Item key="new2" label="Confirm Password">
                <Input
                    value={formData.confirmPassword}
                    name="confirmPassword"
                    type="password"
                    onChange={onChange}
                />
            </Form.Item>
        );
    }
    return (
        <Form onSubmit={onSubmit} {...formItemLayout} className='user-form'>
            {formItems}
            <Form.Item {...buttonItemLayout}>
                <ButtonGroup>
                    <Button className='user-form-logout' onClick={onLogout} type='primary' >Log Out</Button>
                    <Button htmlType='submit' disabled={!validate()} loading={isUpdating}>Change password</Button>
                </ButtonGroup>
            </Form.Item>
        </Form>
    );
}

User.propTypes = {
    appState: PropTypes.shape({
        accountName: PropTypes.string,
        userName: PropTypes.string, 
        userRole: PropTypes.string,
        validationRequired: PropTypes.bool
    })
};