import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Alert, Button, Form, Input, Table, Popconfirm, message, List, Tag } from 'antd';
import PropTypes from 'prop-types';

import { ApiContext } from '../services/HubApi';

import AccountChooser from './AccountChooser';
import RoleChooser from './RoleChooser';

const { Column } = Table;
const ButtonGroup = Button.Group;

const columns = [
    {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
    }, {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
    }, {
        title: 'Currently applied',
        dataIndex: 'inUseApplied',
        key: 'inUseApplied',
        // eslint-disable-next-line react/display-name
        render: field => { 
            return field ? <Tag color='green'>In Use</Tag> : null;
        }
    }
];

function RoleScope(props) {

    const api = useContext(ApiContext);

    const [scopedRoles, setScopedRoles] = useState([]); // All possible roles
    const [loaded, setLoaded] = useState(false);

    const onLoad = useCallback(async () => {
        let allScopes = await api.getScopedRoles();

        let assignedRoles = props.initialRoles || [];

        let reconstructedScopes = {};
        assignedRoles.map((item) => {
            let [scope, id] = item.split('/');

            if (!(scope in reconstructedScopes)) {
                reconstructedScopes[scope] = {};
            }

            reconstructedScopes[scope][id] = item;
            return reconstructedScopes;
        });


        let inUse = [];
        allScopes.forEach((scope) => {

            const thisScope = scope.scope;
            const testScope = reconstructedScopes[thisScope] || {};
            scope.roles.forEach((role) => {
                role.fullKey = `${thisScope}/${role.id}`;

                role.inUseApplied = role.id in testScope;
                if (role.inUseApplied){
                    inUse.push(role.fullKey);
                }
            });
        });
        setScopedRoles(allScopes);
    }, [api, props.initialRoles]);

    useEffect(() => {
        if (!loaded) {
            onLoad();
            setLoaded(true);
        }
    }, [onLoad, loaded]);

    const rowSelection = {
        onChange: (selectedRowKeys) => {
            props.setScopedRoles(selectedRowKeys);
        },
        selectedRowKeys: props.assignedRoles || []
    };

    return <List itemLayout='vertical' dataSource={scopedRoles} renderItem={item => {
        return <List.Item>
            <List.Item.Meta
                title={<strong>{item.scope}</strong>}
            />
            <Table rowKey="fullKey" dataSource={item.roles} columns={columns} rowSelection={rowSelection}
                pagination={false}/>
        </List.Item>;
    }
    }/>;
}

RoleScope.propTypes = {
    initialRoles: PropTypes.arrayOf(PropTypes.string),
    assignedRoles: PropTypes.arrayOf(PropTypes.string),
    setScopedRoles: PropTypes.func,
};

function EditUser(props) {

    const api = useContext(ApiContext);
    const [emptyState] = useState({
        accountID: '',
        userID: '',
        userEmail: '',
        userEmail2: '',
        userName: '',
        password: '',
        role: '',
        scopedRoles: []
    });
    const [formData, setFormData] = useState(emptyState);
    const [isUpdating, setIsUpdating] = useState(false);
    const [mode, setMode] = useState('Edit');

    useEffect(() => {
        if (props.mode === 'Add') {
            setMode(props.mode);
            setFormData(emptyState);
        }
        else if (props.mode === 'Edit') {
            setMode(props.mode);
            setFormData({ ...props.editting, userEmail2: props.editting.userEmail, password: '' });
        }
        else if (props.mode === 'View') {
            setMode(props.mode);
            setFormData({ ...props.editting, userEmail2: props.editting.userEmail, password: '' });
        }
    }, [props, emptyState]);

    useEffect(() => {
        return () => {
            //console.log("EditUser cleanup")
        };
    }, []);

    async function onSubmit(event) {
        event.preventDefault();

        setIsUpdating(true);

        try {
            if (props.mode === 'Add') {
                await api.createUser(props.accountID, formData.userEmail, formData.userName, formData.role, formData.scopedRoles);

                message.success('New user created');
            } else if (props.mode === 'Edit') {
                await api.editUser(formData.accountID, formData.userID, formData.userEmail, formData.userName, formData.password, formData.role, formData.scopedRoles);

                message.success('User edited');
            }
        }
        catch (error) {
            alert(error);
        }

        setIsUpdating(false);
        setFormData(emptyState);
        await props.reList();
    }

    async function onCancel(event) {
        event.preventDefault();
        setIsUpdating(false);
        setFormData(emptyState);
        setMode('Add');
    }

    async function onChange(event) {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    }

    async function onRole(role) {
        setFormData({ ...formData, role: role });
    }

    async function onSetScopedRoles(scopedRoles) {
        setFormData({ ...formData, scopedRoles:scopedRoles });
    }

    function validate() {
        if (mode === 'Add') {
            return formData.userName.length > 0 &&
                   formData.userEmail.length > 0 &&
                   formData.userEmail === formData.userEmail2 &&
                   formData.role.length > 0;
        }
        if (mode === 'Edit') {
            return formData.userName.length > 0 &&
                   formData.userEmail.length > 0 &&
                   formData.userEmail === formData.userEmail2 &&
                   formData.role.length > 0;
        }
        return false;
    }

    const formItemLayout =
        {
            labelCol: { span: 4 },
            wrapperCol: { span: 14 },
        };

    const buttonItemLayout = {
        wrapperCol: { span: 14, offset: 4 },
    };

    const isEditMode = mode === 'Edit';

    const initialScopedRoles = (isEditMode && props.editting ? (props.editting.scopedRoles || []) : []);

    return (
        <div>
            <h3>{mode} User for {props.accountName}</h3>
            <Form onSubmit={onSubmit} {...formItemLayout} className='edit-user-form'>
                <Alert
                    key="validation"
                    type="warning"
                    closable
                    showIcon
                    message="Double check the email address, the user will have to respond to an email to validate their account"
                />
                <Form.Item label="Email Address">
                    <Input
                        name="userEmail"
                        onChange={onChange}
                        placeholder="Email address including @domain.com"
                        value={formData.userEmail}
                    />
                </Form.Item>
                <Form.Item label="Confirm Email Address">
                    <Input
                        name="userEmail2"
                        onChange={onChange}
                        placeholder="Confirm the email address"
                        value={formData.userEmail2}
                    />
                </Form.Item>
                <Form.Item label="Name">
                    <Input
                        name="userName"
                        onChange={onChange}
                        placeholder="User's Name"
                        value={formData.userName}
                    />
                </Form.Item>
                <Form.Item label="Password">
                    <Input
                        name="password"
                        type="password"
                        disabled={mode === 'Add'}
                        onChange={onChange}
                        placeholder={mode === 'Edit' ? 'Leave blank for previous value' : 'Set by user after following email validation link'}
                        value={formData.password}
                    />
                </Form.Item>
                <Form.Item label="Role">
                    <RoleChooser role={formData.role} setRole={onRole}/>
                </Form.Item>

                <Form.Item label="Solution Roles">
                    <RoleScope assignedRoles={formData.scopedRoles} setScopedRoles={onSetScopedRoles}
                        initialRoles={initialScopedRoles}/>
                </Form.Item>


                {(mode !== 'View') ?
                    <Form.Item {...buttonItemLayout}>
                        <ButtonGroup>
                            <Button onClick={onCancel}>Cancel</Button>
                            <Button type='primary' htmlType='submit' disabled={!validate()} loading={isUpdating}>
                                {mode === 'Add' ? 'Add User' : 'Save changes'}
                            </Button>
                        </ButtonGroup>
                    </Form.Item>
                    : <div/>}
            </Form>
        </div>
    );
}

EditUser.propTypes = {
    accountID: PropTypes.string,
    accountName: PropTypes.string,
    mode: PropTypes.string,
    editting: PropTypes.object,
    reList: PropTypes.func
};

export default function UserMgmt(props) {
    const api = useContext(ApiContext);

    const [accountID, setAccountID] = useState(props.appState.accountID);
    const [accountName, setAccountName] = useState(props.appState.accountName);

    const [users, setUsers] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [isDeleting, setDeleting] = useState(false);

    const [emptyState] = useState({
        valid: false,
        accountID: '',
        userID: '',
        userEmail: '',
        userName: '',
        password: '',
        role: '',
        scopedRoles: []
    });
    const [editting, setEditting] = useState(emptyState);

    const onLoad = useCallback(async () => {
        async function loadUserIds() {
            try {
                const userArray = await api.getUsers(accountID);

                const userObjects = userArray.reduce((arr, obj) => {
                    arr.push({ key: obj, loading: true });

                    return arr;
                }, []);

                return userObjects;
            }
            catch (error) {
                console.error(error);
                //alert(error);
            }
        }

        async function loadUserData(id) {
            try {
                let userData = await api.getUser(accountID, id);
                userData['key'] = id;
                userData['loading'] = false;

                return userData;
            }
            catch (error) {
                console.error(error);
                //alert(error);
            }
        }

        setLoading(true);

        let userIds = await loadUserIds();

        let newUsers = [];

        for (let i = 0; i < (userIds ? userIds.length : 0); ++i) {
            const userId = userIds[i].key;
            newUsers[i] = await loadUserData(userId);
        }

        setUsers(newUsers);
        setEditting(emptyState);

        setLoading(false);
    }, [api, accountID, emptyState]);

    useEffect(() => {
        onLoad();
    }, [onLoad]);

    useEffect(() => {
        return () => {
            //console.log("UserMgmt cleanup")
        };
    }, []);

    async function reList() {
        // Used when the users are added/editted
        onLoad();
    }

    async function onDelete(id, event) {
        event.preventDefault();

        setDeleting(true);

        try {
            await api.deleteUser(accountID, id);

            let newUsers = users.filter(op => op.key !== id);
            setUsers(newUsers);

            message.success('User deleted');

        } catch (error) {
            alert(error);
            console.error(error);
        }

        setDeleting(false);
    }

    async function onEdit(id, event) {
        event.preventDefault();

        for (let i = 0; i < users.length; ++i) {
            if (users[i].key === id) {
                setEditting({
                    valid: true,
                    accountID: users[i].accountID,
                    userID: users[i].userID,
                    userEmail: users[i].userEmail,
                    userName: users[i].userName,
                    password: users[i].password,
                    role: users[i].role,
                    scopedRoles: users[i].scopedRoles
                });
            }
        }
    }

    async function setAccountDetails(id, name) {
        setAccountID(id);
        setAccountName(name);
    }

    const page = [];

    if (['mkOps', 'mkSales'].includes(props.appState.userRole)) {
        const formItemLayout =
            {
                labelCol: { span: 4 },
                wrapperCol: { span: 14 },
            };

        page.push(
            <Form {...formItemLayout} key="acc" className='choose-account-form'>
                <Form.Item label="Choose account">
                    <AccountChooser accountID={accountID} setAccountDetails={setAccountDetails}/>
                </Form.Item>
            </Form>
        );
    }

    
    page.push(
        <Table
            key="table"
            bordered
            dataSource={users}
            loading={isLoading}
            rowKey={record => record.key}
        >
            <Column title='Email' dataIndex='userEmail' sorter={(a, b) => a.userEmail.localeCompare(b.userEmail)} />
            <Column title='Name' dataIndex='userName'/>
            <Column title='Role' dataIndex='role'/>
            <Column title='Action' dataIndex=''
                render={(text, record) => (
                    (props.readOnly) ?
                        <Button onClick={(event) => {
                            onEdit(record.key, event);
                        }}> Details </Button>
                        :
                        <div>
                            <Popconfirm
                                title="Really delete this user?"
                                onConfirm={(event) => {
                                    onDelete(record.key, event);
                                }}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button loading={isDeleting}>Delete</Button>
                            </Popconfirm>
                            <Button onClick={(event) => {
                                onEdit(record.key, event);
                            }}> Edit </Button>
                        </div>
                )}
            />
        </Table>
    );
    

    if (editting.valid) {
        if (props.readOnly) {
            page.push(
                <EditUser key="view" mode="View" accountName={accountName} accountID={accountID} reList={reList} editting={editting}/>
            );
        } else {
            page.push(
                <EditUser key="edit" mode="Edit" accountName={accountName} accountID={accountID} reList={reList} editting={editting}/>
            );
        }
    } else {
        if (!props.readOnly) {
            page.push(
                <EditUser key="add" mode="Add" accountName={accountName} accountID={accountID} reList={reList}/>
            );
        }
    }

    return (
        <div>
            {page}
        </div>
    );
}

UserMgmt.propTypes = {
    appState: PropTypes.shape({
        accountID:  PropTypes.string,
        accountName: PropTypes.string,
        userRole: PropTypes.string,
    }),
    readOnly: PropTypes.bool
};