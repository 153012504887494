import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, DatePicker, Form, Input, Table, Popconfirm, message } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';

import { ApiContext } from '../services/HubApi';

import AccountChooser from './AccountChooser';
import SolutionChooser from './SolutionChooser';

const { Column } = Table;
const ButtonGroup = Button.Group;

function EditEntitlement(props) {

    const api = useContext(ApiContext);
    const [emptyState] = useState({
        accountID: '',
        //entitlementID: '',
        solutionName: '',
        //creationDate: '',
        expiryDate: moment().add(3, 'months'),
        erpReference: '',
        custReference: '',
        quotas: {}
    });
    const [formData, setFormData] = useState(emptyState);
    const [isUpdating, setIsUpdating] = useState(false);
    const [mode, setMode] = useState('Edit');
    const [confirmFlag, setConfirmFlag] = useState(false);

    useEffect(() => {
        if (props.mode === 'Add') {
            setMode(props.mode);
            setFormData({ ...emptyState, accountID: props.accountID });
        }
        else if (props.mode === 'Edit' || props.mode === 'View') {

            var expiryDate = moment().add(3, 'months');
            if (props.editting.expiryDate !== '') {
                expiryDate = moment(props.editting.expiryDate);
            }

            var quotas = {};
            for (let i = 0; i < (props.editting.quotas ? props.editting.quotas.length : 0); ++i) {
                let sname = props.editting.quotas[i].name;
                quotas[sname] = props.editting.quotas[i].value.toString();
            }

            setMode(props.mode);
            setFormData({ ...props.editting, expiryDate: expiryDate, quotas: quotas });
        }

    }, [props, emptyState]);

    useEffect(() => {
        return () => {
            //console.log("EditEntitlement cleanup")
        };
    }, []);

    function reformat(input) {
        let entitlement = {};

        if (input.entitlementID !== undefined) {entitlement.entitlementID = input.entitlementID;}
        if (input.accountID !== undefined) {entitlement.accountID = input.accountID;}
        if (input.solutionName !== undefined) {entitlement.solutionName = input.solutionName;}

        if (input.creationDate !== undefined) {entitlement.creationDate = input.creationDate;}
        if (input.expiryDate !== undefined) {entitlement.expiryDate = input.expiryDate.format('YYYY-MM-DD');}
        if (input.erpReference !== undefined) {entitlement.erpReference = input.erpReference;}
        if (input.custReference !== undefined) {entitlement.custReference = input.custReference;}

        if (input.quotas !== undefined) {
            var quotas = [];
            for (var quota in input.quotas) {
                quotas.push(
                    { name: quota, value: input.quotas[quota] }
                );
            }
            entitlement.quotas = quotas;
        }

        return entitlement;
    }

    async function onSubmit(event) {
        event.preventDefault();

        setIsUpdating(true);

        if (props.mode === 'Add') {
            setConfirmFlag(true);
            setIsUpdating(false);
            return;
        }

        try {
            if (props.mode === 'Edit') {
                await api.editEntitlement(formData.accountID, formData.entitlementID, reformat(formData));

                message.success('Entitlement edited');
            }
        }
        catch (error) {
            alert(error);
        }

        setIsUpdating(false);
        setFormData(emptyState);
        await props.reList();
    }

    async function onCancel(event) {
        event.preventDefault();
        setIsUpdating(false);
        setFormData(emptyState);
        setMode('Add');
    }

    async function onChange(event) {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    }

    async function onQuotaChange(event) {
        const { name, value } = event.target;
        const quotas = formData.quotas;
        quotas[name] = value;
        setFormData({ ...formData, quotas: quotas });
    }

    async function onConfirm(event) {
        event.preventDefault();

        try {
            if (props.mode === 'Add') {
                await api.createEntitlement(formData.accountID, reformat(formData));
                message.success('New entitlement created');
            }
        }
        catch (error) {
            alert(error);
        }

        setIsUpdating(false);
        setConfirmFlag(false);
        setFormData(emptyState);
        await props.reList();
    }

    async function onCancelConfirm(event) {
        event.preventDefault();
        setConfirmFlag(false);
    }

    async function setSolutionName(solutionName) {
        const solutionData = await api.getSolution(solutionName);

        let quotas = {};
        for (let i = 0; i < (solutionData.quotas ? solutionData.quotas.length : 0); ++i) {
            let sname = solutionData.quotas[i];
            quotas[sname] = '';
        }

        setFormData({ ...formData, solutionName: solutionName, quotas: quotas });
    }

    function validate() {
        if (props.disabled) { return false; }

        if (formData.solutionName === undefined) { return false; }
        if (formData.expiryDate === undefined) { return false; }
        if (formData.erpReference === undefined) { return false; }
        if (formData.custReference === undefined) { return false; }
        if (formData.quotas === undefined) { return false; }

        if (formData.solutionName.length < 1) { return false; }
        if (formData.expiryDate.isSameOrBefore(moment())) { return false; }
        if (formData.erpReference.length < 1) {return false; }
        if (formData.custReference.length < 1) {return false; }

        for (var quota in formData.quotas) {
            var value = formData.quotas[quota];
            if (value.match(/^([0-9]+\.[0-9]+|[0-9]+)$/) === null) { return false; }
        }

        return true;
    }

    const formItemLayout =
        {
            labelCol: { span: 4 },
            wrapperCol: { span: 14 },
        };

    const buttonItemLayout = {
        wrapperCol: { span: 14, offset: 4 },
    };

    const quotaEntries = [];

    // Loop through solution data and all entries
    for (var quota in formData.quotas) {
        quotaEntries.push(
            <Form.Item label={quota} key={quota}>
                <Input
                    disabled={mode === 'View'}
                    name={quota}
                    onChange={onQuotaChange}
                    placeholder={quota}
                    value={formData.quotas[quota]}
                />
            </Form.Item>
        );
    }

    const confirmationDialogTemplate = 
        <Form onSubmit={onConfirm} {...formItemLayout} className='entitlement-confirm-form'>
            <Form.Item {...buttonItemLayout}>
                <ButtonGroup>
                    <Button onClick={onCancelConfirm} disabled={props.disabled}>Back</Button>
                    <Button type='primary' htmlType='submit' disabled={props.disabled}>
                        Grant {props.accountName} entitlement to use {formData.solutionName}
                    </Button>
                </ButtonGroup>
            </Form.Item>
        </Form>;

    const confirmationDialog = confirmFlag ? confirmationDialogTemplate : null;

    return (
        <div>
            <h3>{mode} Entitlement for {props.accountName}</h3>
            <Form onSubmit={onSubmit} {...formItemLayout} className='edit-entitlement-form'>
                <Form.Item label="Select solution">
                    <SolutionChooser
                        disabled={props.disabled}
                        solutionName={formData.solutionName}
                        setSolutionName={setSolutionName}
                    />
                </Form.Item>
                <Form.Item label="ERP Reference">
                    <Input
                        disabled={mode === 'Edit'}
                        name="erpReference"
                        onChange={onChange}
                        placeholder="Reference for this purchase from our ERP system"
                        value={formData.erpReference}
                    />
                </Form.Item>
                <Form.Item label="Customer Reference">
                    <Input
                        disabled={mode === 'Edit'}
                        name="custReference"
                        onChange={onChange}
                        placeholder="Customer's reference for this purchase"
                        value={formData.custReference}
                    />
                </Form.Item>
                <Form.Item label="Expiry date">
                    <DatePicker
                        allowClear={false}
                        disabled={props.disabled}
                        name="expiryDate"
                        onChange={(date) => {setFormData({ ...formData, expiryDate: date });}}
                        value={formData.expiryDate}
                    />
                </Form.Item>
                {quotaEntries}


                {(mode !== 'View') ?
                    <Form.Item {...buttonItemLayout}>
                        <ButtonGroup>
                            <Button onClick={onCancel}>Cancel</Button>
                            <Button type='primary' htmlType='submit' disabled={!validate()} loading={isUpdating}>
                                {mode === 'Add' ? 'Add Entitlement' : 'Save changes'}
                            </Button>
                        </ButtonGroup>
                    </Form.Item>
                    : <div/>}
            </Form>
            {confirmationDialog}
        </div>
    );
}

EditEntitlement.propTypes = {
    accountID: PropTypes.string,
    accountName: PropTypes.string,
    mode: PropTypes.string,
    editting: PropTypes.object,
    reList: PropTypes.func,
    disabled: PropTypes.bool
};

export default function EntitlementMgmt(props) {
    const api = useContext(ApiContext);

    const [accountID, setAccountID] = useState(props.appState.accountID);
    const [accountName, setAccountName] = useState(props.appState.accountName);

    const [entitlements, setEntitlements] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [isDeleting, setDeleting] = useState(false);

    const [emptyState] = useState({
        accountID: '',
        entitlementID: '',
        solutionName: '',
        creationDate: '',
        expiryDate: '',
        erpReference: '',
        custReference: '',
        quotas: [],
    });
    const [editting, setEditting] = useState(emptyState);

    const onLoad = useCallback(async () => {
        async function loadEntitlementIds() {
            try {
                const entitlementArray = await api.getEntitlements(accountID);

                const entitlementObjects = entitlementArray.reduce((arr, obj) => {
                    arr.push({ key: obj, loading: true });

                    return arr;
                }, []);

                return entitlementObjects;
            }
            catch (error) {
                console.error(error);
                //alert(error);
            }
        }

        async function loadEntitlementData(id) {
            try {
                let entitlementData = await api.getEntitlement(accountID, id);
                entitlementData['key'] = id;
                entitlementData['loading'] = false;

                return entitlementData;
            }
            catch (error) {
                console.error(error);
                //alert(error);
            }
        }

        setLoading(true);

        let entitlementIds = await loadEntitlementIds();

        let newEntitlements = [];

        for (let i = 0; i < (entitlementIds ? entitlementIds.length : 0); ++i) {
            const entitlementId = entitlementIds[i].key;
            newEntitlements[i] = await loadEntitlementData(entitlementId);
        }

        setEntitlements(newEntitlements);
        setEditting(emptyState);

        setLoading(false);
    }, [api, emptyState, accountID]);

    useEffect(() => {
        onLoad();
    }, [onLoad]);

    useEffect(() => {
        return () => {
            //console.log("EntitlementMgmt cleanup")
        };
    }, []);

    async function reList() {
        // Used when the entitlements are added/editted
        onLoad();
    }

    async function onDelete(id, event) {
        event.preventDefault();

        setDeleting(true);

        try {
            await api.deleteEntitlement(accountID, id);

            let newEntitlements = entitlements.filter(op => op.key !== id);
            setEntitlements(newEntitlements);

            message.success('Entitlement deleted');

        } catch (error) {
            alert(error);
            console.error(error);
        }

        setDeleting(false);
    }

    async function onEdit(id, event) {
        event.preventDefault();

        for (let i = 0; i < entitlements.length; ++i) {
            if (entitlements[i].key === id) {
                setEditting({ ...entitlements[i], valid: true });
            }
        }
    }

    async function setAccountDetails(id, name) {
        setAccountID(id);
        setAccountName(name);
    }

    const renderQuotas = (...[value, , ]) => {

        const columns = [
            { title: 'Name', dataIndex: 'name', key: 'name' },
            { title: 'Value', dataIndex: 'value', key: 'value' },
        ];

        return {
            children: <Table showHeader={false} pagination={false} rowKey="name" columns={columns} dataSource={value}/>,
        };
    };

    const page = [];

    if (['mkOps', 'mkSales'].includes(props.appState.userRole)) {
        const formItemLayout =
            {
                labelCol: { span: 4 },
                wrapperCol: { span: 14 },
            };

        page.push(
            <Form {...formItemLayout} key="acc" className='choose-account-form'>
                <Form.Item label="Choose account">
                    <AccountChooser accountID={accountID} setAccountDetails={setAccountDetails}/>
                </Form.Item>
            </Form>
        );
    }

    
    page.push(
        <Table
            key="table"
            bordered
            dataSource={entitlements}
            loading={isLoading}
            rowKey={record => record.key}
        >
            <Column title='Solution' dataIndex='solutionName'/>
            <Column title='ERP Reference' dataIndex='erpReference'/>
            <Column title='Customer Reference' dataIndex='custReference'/>
            <Column title='Creation Date' dataIndex='creationDate'/>
            <Column title='Expiry Date' dataIndex='expiryDate'/>
            <Column title='Quotas ' dataIndex='quotas' render={renderQuotas}/>
            <Column title='Action' dataIndex=''
                render={(text, record) => (
                    (props.readOnly) ?
                        <div/>
                        :
                        <div>
                            <Popconfirm
                                title="Really delete this entitlement?"
                                onConfirm={(event) => {
                                    onDelete(record.key, event);
                                }}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button loading={isDeleting}>Delete</Button>
                            </Popconfirm>
                            <Button onClick={(event) => {
                                onEdit(record.key, event);
                            }}> Edit </Button>
                        </div>
                )}
            />
        </Table>
    );
    
    if (editting.valid) {
        if (props.readOnly) {
            page.push(
                <EditEntitlement key="view" mode="View" accountName={accountName} accountID={accountID} reList={reList} editting={editting}/>
            );
        } else {
            page.push(
                <EditEntitlement key="edit" mode="Edit" accountName={accountName} accountID={accountID} reList={reList} editting={editting}/>
            );
        }
    } else {
        if (!props.readOnly) {
            page.push(
                <EditEntitlement key="add" mode="Add" accountName={accountName} accountID={accountID} reList={reList}/>
            );
        }
    }

    return (
        <div>
            {page}
        </div>
    );
}

EntitlementMgmt.propTypes = {
    appState: PropTypes.shape({
        accountID:  PropTypes.string,
        accountName: PropTypes.string,
        userRole: PropTypes.string,
    }),
    readOnly: PropTypes.bool
};