import React, { useCallback, useEffect, useState } from 'react';
import { Layout, Icon, Modal } from 'antd';

import './App.css';

import HubApi, { ApiContext } from './services/HubApi';
import { Triggers, TriggerContext } from './services/Triggers';

import NoAccess from './components/NoAccess';
import User from './components/User';
import Login from './components/Login';

import SolutionHome from './components/SolutionHome';
import Documentation from './components/Documentation';

import SolutionMgmt from './components/SolutionMgmt';
import AccountMgmt   from './components/AccountMgmt';
import UserMgmt from './components/UserMgmt';
import EntitlementMgmt  from './components/EntitlementMgmt';
import Reports from './components/Reports';
import ReportCreate from './components/ReportCreate';

import MKFooter from './components/MKFooter';
import MKHeader from './components/MKHeader';

import {
    BrowserRouter as Router,
    Switch,
    Route,
} from 'react-router-dom';

import MkNav from './components/Nav';
import ErrorBoundary from './components/ErrorBoundary';

const { confirm: popupConfirm } = Modal;
const { Content } = Layout;

// eslint-disable-next-line no-undef
var Url = require('url-parse');

var restUrl = '/api/v1.0/';

// eslint-disable-next-line no-undef
if (process.env.NODE_ENV === 'development' && typeof process.env.REACT_APP_REST_URL !== 'undefined') {
    // eslint-disable-next-line no-undef    
    restUrl = process.env.REACT_APP_REST_URL;
}

console.log('REST URL: ' + restUrl);
// eslint-disable-next-line no-undef
if (typeof process.env.REACT_APP_VERSION !== 'undefined') {
    // eslint-disable-next-line no-undef   
    console.log('Frontend version: ' + process.env.REACT_APP_VERSION);
}


const onInactivityTimeout = (api) => {
    popupConfirm({
        title: 'Inactivity Timeout',
        icon: <Icon type='exclamation'/>,
        content: 'You have been automatically logged out due to inactivity. You will now be redirected to the Login page.',
        okText: 'Ok',
        okType: 'primary',
        cancelButtonProps: {
            style: {
                display: 'none'
            }
        },
        onOk() {
            api.logout();
        }
    });
};

const api = new HubApi(restUrl, onInactivityTimeout);
const triggers = new Triggers();

const homepageConfigs = {
    'mkOps': [
        { name: 'Accounts',          readOnly: false, type: 'AccountMgmt' },
        { name: 'Users',             readOnly: false, type: 'UserMgmt' },
        { name: 'Entitlements',      readOnly: false, type: 'EntitlementMgmt' },
        { name: 'Reports',           readOnly: true,  type: 'Reports' },
        { name: 'CreateReport',        readOnly: false, type: 'ReportCreate' },
    ],
    'mkSales': [
        { name: 'Accounts',          readOnly: false, type: 'AccountMgmt' },
        { name: 'Users',             readOnly: false, type: 'UserMgmt' },
        { name: 'Entitlements',      readOnly: false, type: 'EntitlementMgmt' },
    ],
    'admin': [
        { name: 'Entitlements',      readOnly: true,  type: 'EntitlementMgmt' },
        { name: 'Users',             readOnly: false, type: 'UserMgmt' },
        { name: 'Reports',           readOnly: true,  type: 'Reports' },
        { name: 'CreateReport',        readOnly: false, type: 'ReportCreate' },
    ],
    'user': [
        { name: 'Entitlements',      readOnly: true,  type: 'EntitlementMgmt' },
        { name: 'Reports',           readOnly: true,  type: 'Reports' },
    ],
};

export default function App() {

    const [appState, appStateChange] = useState({
        redrawEvent: 0,
        hasAuthenticated: false,
        validationRequired: false,
        accountID: '',
        accountName: '',
        userID: '',
        userName: '',
        userRole: ''
    });

    api.stateVariables = {
        appState,
        appStateChange
    };

    const [stillLoading, setLoading] = useState(true);

    const makeRoutes = useCallback((routeName, appState) => {

        let returnRoute = <div>Error</div>;

        switch (routeName.type) {
        case 'AccountMgmt':
            returnRoute = <AccountMgmt   readOnly={routeName.readOnly} appState={appState} appStateChange={appStateChange}/>;
            break;         
        case 'UserMgmt':
            returnRoute = <UserMgmt   readOnly={routeName.readOnly} appState={appState} appStateChange={appStateChange}/>;
            break;
        case 'EntitlementMgmt':
            returnRoute = <EntitlementMgmt   readOnly={routeName.readOnly} appState={appState} appStateChange={appStateChange}/>;
            break;
        case 'Reports':
            returnRoute = <Reports   readOnly={routeName.readOnly} appState={appState} appStateChange={appStateChange}/>;
            break;
        case 'ReportCreate':
            returnRoute = <ReportCreate     appState={appState} appStateChange={appStateChange}/>;
            break;
        default:
            returnRoute = <p>Missing pane</p>;
            break;
        }

        return(returnRoute);
    }, []);

    const onLoad = useCallback(async () => {
        // Try and get the current user details if we were already logged in
        const user = await api.currentUser();
        
        if (user['accountName'] !== undefined) {
            await api.refreshTick();

            appStateChange({
                redrawEvent: 0,
                hasAuthenticated: true,
                validationRequired: (user.password === 'Validation Required'),
                accountID: user.accountID,
                accountName: user.accountName,
                userID: user.userID,
                userName: user.userName,
                userRole: user.role
            });
        }

        setLoading(false);
    }, []);

    useEffect(() => {
        onLoad();
    }, [onLoad]);


    var url = new Url(window.location.href, true);

    if (stillLoading) {
        return(<div/>);
    }

    // If the user has authenticated and we have a request for a redirection
    // then generate the correct URL, remove the cookie and bounce them

    if (appState.hasAuthenticated) {
        if (url.query['redir-solution'] !== undefined) {
            var solution = url.query['redir-solution'];
            if (solution.match(/^[a-zA-Z0-9-]+$/) !== null) {
                var page     = '';
                if (url.query['redir-page'] !== undefined) {
                    page = url.query['redir-page'];
                }
                var newUrl = url.protocol + '//' + solution + '.aas.mediakind.com/' + page;
                window.location = newUrl;
                return (<p>Please wait, redirecting you....</p>);
            }
        }
    }

    if (!appState.hasAuthenticated)
    {
        return (
            < Layout key='layout'>
                <MKHeader/>
                <Content>
                    <ApiContext.Provider value={api}>
                        <Login appState={appState} appStateChange={appStateChange}></Login>
                    </ApiContext.Provider>
                </Content>
                <MKFooter/>
            </Layout>

        );
    }

    const homepageConfig = homepageConfigs[appState.userRole];


   

    let tabs = [];

    for (let i = 0; i < homepageConfig.length; ++i) {
        let tab = homepageConfig[i];
        tabs.push(
            <Route key={tab.name.toLowerCase()} path={'/' + tab.name.toLowerCase()}>
                <ErrorBoundary key={tab.name.toLowerCase() + 'Error'} >
                    {makeRoutes(tab, appState)}
                </ErrorBoundary>
            </Route>
        );
    }

    const content = <div key='content'>
        <MkNav role={appState.userRole}/>
        <Switch>
            <Route key='1' exact path='/'>
                <ErrorBoundary key='LandingError'>
                    {appState.userRole === 'admin' || appState.userRole === 'user' ? <SolutionHome readOnly={true} appState={appState} appStateChange={appStateChange}/> : <SolutionMgmt readOnly={true} appState={appState} appStateChange={appStateChange}/>}
                </ErrorBoundary>
            </Route>
            {tabs}
            <Route path='/documentation'>
                <ErrorBoundary key='DocumentError'>
                    <Documentation role={appState.userRole}/>
                </ErrorBoundary>
            </Route>
            <Route path='/user'>
                <ErrorBoundary key='UserError'>
                    <User appState={appState} appStateChange={appStateChange}/>
                </ErrorBoundary>
            </Route>
            <Route>
                <NoAccess appState={appState} appStateChange={appStateChange}/>
            </Route>
        </Switch>
        
    </div>;

    return (
        <Router key='route'>
            <Layout>
                <MKHeader appState={appState}/>
                <ApiContext.Provider value={api}>
                    <TriggerContext.Provider value={triggers}>
                        <Content>
                            {content}
                        </Content>
                    </TriggerContext.Provider>
                </ApiContext.Provider>
                <MKFooter/>
            </Layout>
        </Router>

        
    );
    

}
