import React from 'react';
import Cookies from 'js-cookie';


const KEEP_ALIVE_INTERVAL_MS = 20 * 1000; // check to see if we should refresh every 20 seconds
const INACTIVITY_TIMEOUT_MS = 15 * 60 * 1000; // Inactivity timeout at 15 minutes

class HubApi {

    constructor(url, onInactivityCallback) {
        this.url = url;
        this.token = Cookies.get('hub-token') || null;
        this.loginTime = 0;
        this.refreshInterval = 2 * 60 * 1000; // 2 minutes then refresh the token
        this.lastOperationTime = 0;
        this.onInactivityCallback = onInactivityCallback ? onInactivityCallback : null;
        this.stateVariables = null; // app state updating members
    }

    getApiUrl() {
        return this.url;
    }

    async refreshTick() {
        if (this.refreshTimer !== null) {
            // prevent any imminent refreshes
            clearTimeout(this.refreshTimer);
        }

        const elapsedSinceRefresh = Date.now() - this.loginTime;
        const elapsedSinceLastOperation = Date.now() - this.lastOperationTime;

        if (elapsedSinceLastOperation > INACTIVITY_TIMEOUT_MS) {
            if (this.onInactivityCallback) {
                this.onInactivityCallback(this);
            }
            console.log('Logging user out due to inactivity.');
            // Do not refresh anymore
        } else if (elapsedSinceRefresh > this.refreshInterval) {
            console.info('Refreshing use token');
            await this.refresh();
            this.resetKeepAliveTimer();
        } else {
            this.resetKeepAliveTimer();
        }

    }

    markOperation() {
        this.lastOperationTime =  Date.now();
    }

    resetKeepAliveTimer() {
        if (this.refreshTimer !== null) {
            clearTimeout(this.refreshTimer);
        }

        this.refreshTimer = setTimeout(() => this.refreshTick(), KEEP_ALIVE_INTERVAL_MS);
    }

    async login(accountName, userEmail, password) {

        const contents = JSON.stringify({
            accountName: accountName,
            userEmail: userEmail,
            password: password
        });

        const response = await fetch(this.url + 'auth/login', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: contents,
        });

        let currentUser = null;

        if (response.ok) {

            for (let pair of response.headers.entries()) {
                if (pair[0] === 'x-api-version') {
                    console.log('API Version: ' + pair[1]);
                }
            }

            this.token = await response.text();
            this.loginTime = Date.now();
            Cookies.set('hub-token', this.token, {
                domain: '.aas.mediakind.com',
                path: '/'
            });
    

            this.resetKeepAliveTimer();
            this.markOperation();

            currentUser = this.currentUser();
        }

        return currentUser;
    }

    async loginMSAL(accountName) {

        const contents = JSON.stringify({
            accountName: accountName
        });

        // pass in the query string as well so ?redir-solutiom=... can be preserved
        const response = await fetch(this.url + 'sso/login' + window.location.search, {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: contents,
        });

        let currentUser = null;

        if (response.ok) {
            const json = await response.json();
            const loginUri = json['login_uri'];
            window.location = loginUri;
        }

        return currentUser;
    }

    async currentUser() {

        if (this.token === null) {
            return {};
        }

        const response = await fetch(this.url + 'auth/decode', {
            method: 'GET',
            headers: {
                'Authorization': ('Bearer ' + this.token),
            },
        });
        if (!response.ok) {
            return {};
        }

        this.markOperation();

        const user = await response.json();

        return user;        
    }

    async refresh() {
        const response = await fetch(this.url + 'auth/refresh', {
            method: 'GET',
            headers: {
                'Authorization': ('Bearer ' + this.token),
            },
        });
        if (!response.ok) {
            throw Error(response.statusText);
        }

        const token = await response.text();

        this.token = token;
        this.loginTime = Date.now();
    }

    async password(oldPassword, newPassword) {
        this.markOperation();
        await this.refreshTick();

        const contents = JSON.stringify({
            oldPassword: oldPassword,
            newPassword: newPassword
        });

        const response = await fetch(this.url + 'auth/password', {
            method: 'PUT',
            headers: {
                'Authorization': ('Bearer ' + this.token),
                'content-type': 'application/json'
            },
            body: contents
        });
        if (!response.ok) {
            throw Error(response.statusText);
        }

        const token = await response.text();

        this.token = token;
        this.loginTime = Date.now();
    }

    logout() {

        if (this.refreshTimer !== null) {
            // prevent any imminent refreshes
            clearTimeout(this.refreshTimer);
        }

        this.stateVariables.appStateChange({
            ...this.stateVariables.appState,
            hasAuthenticated: false,
            validationRequired: false,
            accountName: '',
            userName: '',
            userRole: ''
        });

        this.token = null;
        this.loginTime = 0;
        this.lastOperationTime = 0;
        if (window.location.hostname !== 'localhost') {
            Cookies.remove('hub-token', {
                'domain': '.aas.mediakind.com',
                'path': '/'
            });
        } else {
            Cookies.remove('hub-token', {
                'path': '/'
            });
        }
    }

    // Generics /////////////////////////////////////////////////////////

    async getObject(path) {

        this.markOperation();
        await this.refreshTick();

        const response = await fetch(this.url + path, {
            method: 'GET',
            headers: {
                'Authorization': ('Bearer ' + this.token),
            },
        });

        if (!response.ok) {
            const errors = await response.json();
            console.error(errors);
            throw Error(response.statusText);
        }

        return await response.json();        
    }

    async createObject(path, contents) {

        this.markOperation();
        await this.refreshTick();

        const response = await fetch(this.url + path, {
            method: 'POST',
            headers: {
                'Authorization': ('Bearer ' + this.token),
                'content-type': 'application/json'
            },
            body: contents,
        });

        if (!response.ok) {
            const errors = await response.json();
            console.error(errors);
            throw Error(response.statusText);
        }

        return await response.json();
    }

    async editObject(path, contents) {

        this.markOperation();
        await this.refreshTick();

        const response = await fetch(this.url + path, {
            method: 'PUT',
            headers: {
                'Authorization': ('Bearer ' + this.token),
                'content-type': 'application/json'
            },
            body: contents,
        });

        if (!response.ok) {
            const errors = await response.json();
            console.error(errors);
            throw Error(response.statusText);
        }

        return await response.json();
    }

    async deleteObject(path) {

        this.markOperation();
        await this.refreshTick();

        const response = await fetch(this.url + path, {
            method: 'DELETE',
            headers: {
                'Authorization': ('Bearer ' + this.token),
                'content-type': 'application/json'
            },
        });

        if (!response.ok) {
            throw Error(response.statusText);
        }

        return await response.json();
    }

    // Specifics ///////////////////////////////////////////////////////////

    async getRoles() {
        return await this.getObject('auth/roles');
    }

    async getAccounts() {
        return await this.getObject('accounts');
    }

    async getAccountNames() {
        return await this.getObject('accounts?returnField=accountName');
    }

    async getAccountNamesForERP(erpId) {
        return await this.getObject('accounts?returnField=accountName&queryField=erpId&queryValue=' + erpId);
    }

    async getAccountNamesForReports() {
        let currentUser = await this.currentUser();
        return await this.getObject(
            'accounts/' + currentUser['accountID'] + '/reports/accounts');
    }

    async getAccount(accountName) {
        return await this.getObject('accounts/' + accountName);
    }

    async deleteAccount(accountName) {
        return await this.deleteObject('accounts/' + accountName);
    }

    async createAccount(accountName, description, erpId, erpCustomerId) {
        const contents = JSON.stringify({
            'accountName': accountName,
            'description': description,
            'erpId': erpId,
            'erpCustomerId': erpCustomerId,
        });

        return await this.createObject('accounts', contents);
    }

    async editAccount(accountID, accountName, description, erpId, erpCustomerId) {
        const contents = {
            'accountID': accountID,
            'accountName': accountName,
            'description': description,
            'erpId': erpId,
            'erpCustomerId': erpCustomerId,
        };

        return await this.editObject('accounts/' + accountID, JSON.stringify(contents));
    }

    async getSolutions() {
        return await this.getObject('solutions');
    }

    async getSolution(solutionName) {
        return await this.getObject('solutions/' + solutionName);
    }

    async deleteSolution(solutionName) {
        return await this.deleteObject('solutions/' + solutionName);
    }

    async createSolution(solutionName, description, hubURL, dimensions) {
        const contents = JSON.stringify({
            'solutionName': solutionName,
            'description': description,
            'hubURL': hubURL,
            'dimensions': dimensions,
        });

        return await this.createObject('solutions', contents);
    }

    async editSolution(solutionName, description, hubURL, dimensions) {
        const contents = JSON.stringify({
            'solutionName': solutionName,
            'description': description,
            'hubURL': hubURL,
            'dimensions': dimensions,
        });

        return await this.editObject('solutions/' + solutionName, contents);
    }

    async getUsers(accountID) {
        return await this.getObject('accounts/' + accountID + '/users');
    }

    async getUser(accountID, userID) {
        return await this.getObject('accounts/' + accountID + '/users/' + userID);
    }

    async deleteUser(accountID, userID) {
        return await this.deleteObject('accounts/' + accountID + '/users/' + userID);
    }

    async createUser(accountID, userEmail, userName, role, scopedRoles) {
        const contents = JSON.stringify({
            'accountID': accountID,
            'userEmail': userEmail,
            'userName': userName,
            'role': role,
            'scopedRoles': scopedRoles
        });

        return await this.createObject('accounts/' + accountID + '/users', contents);
    }

    async editUser(accountID, userID, userEmail, userName, password, role, scopedRoles) {
        const data = {
            'accountID': accountID,
            'userID': userID,
            'userEmail': userEmail,
            'userName': userName,
            'role': role,
            'scopedRoles': scopedRoles
        };

        if (password !== '') {
            data['password'] = password;
        }
        const contents = JSON.stringify(data);

        return await this.editObject('accounts/' + accountID + '/users/' + userID, contents);
    }

    async getEntitlements(accountID) {
        return await this.getObject('accounts/' + accountID + '/entitlements');
    }

    async getEntitlementsForSolution(accountID, solutionName) {
        return await this.getObject('accounts/' + accountID + '/entitlements?solution=' + solutionName);
    }

    async getEntitlement(accountID, id) {
        return await this.getObject('accounts/' + accountID + '/entitlements/' + id);
    }

    async createEntitlement(accountID, data) {
        const contents = JSON.stringify(data);

        return await this.createObject('accounts/' + accountID + '/entitlements', contents);
    }

    async editEntitlement(accountID, id, data) {
        const contents = JSON.stringify(data);

        return await this.editObject('accounts/' + accountID + '/entitlements/' + id, contents);
    }

    async deleteEntitlement(accountID, id) {
        return await this.deleteObject('accounts/' + accountID + '/entitlements/' + id);
    }

    async getReports(accountID) {
        return await this.getObject('accounts/' + accountID + '/reports');
    }

    async getReport(accountID, reportID) {
        return await this.getObject('accounts/' + accountID + '/reports/' + reportID);
    }

    async createReport(accountID, data) {
        const contents = JSON.stringify(data);
        let currentUser = await this.currentUser();
        return await this.createObject('accounts/' + currentUser['accountID'] + '/reports', contents);
    }

    async getScopedRoles() {
        return await this.getObject('roles');
    }
}

const ApiContext = React.createContext(null);

export {
    HubApi,
    ApiContext,
};

export default HubApi;
