import React, { useContext, useEffect, useState } from 'react';
import { Button, DatePicker, Form, message } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';

import { ApiContext } from '../services/HubApi';
import { TriggerContext } from '../services/Triggers';

import AccountChooser from './AccountChooser';
import SolutionChooser from './SolutionChooser';

const ButtonGroup = Button.Group;

export default function ReportCreate(props) {

    const api = useContext(ApiContext);
    const triggers = useContext(TriggerContext);

    const [emptyState] = useState({
        accountID: '',
        accountName: '',
        solutionName: '',
        // Subtract 1 day so that the default date is the previous day as you cannot make a report for the current day
        utcStartTime: moment().subtract(1, 'day').startOf('day'),
        utcEndTime: moment().subtract(1, 'day').endOf('day'),
    });
    const [formData, setFormData] = useState(emptyState);
    const [isUpdating, setIsUpdating] = useState(false);

    useEffect(() => {
        setFormData({ ...emptyState, accountID: props.appState.accountID, accountName: props.appState.accountName });
    }, [props, emptyState]);

    async function onSubmit(event) {
        event.preventDefault();

        try {
            const data = {
                accountID: formData.accountID,
                solutionName: formData.solutionName,
            };

            data['utcStartTime'] = formData.utcStartTime.format('YYYY-MM-DD') + 'T00:00:00Z';
            data['utcEndTime'] = formData.utcEndTime.format('YYYY-MM-DD') + 'T23:59:59Z';

            await api.createReport(formData.accountID, data);
            message.success('New report created');
            triggers.raise('New Report');
        }
        catch (error) {
            alert(error);
        }

        setIsUpdating(false);
        setFormData({ ...emptyState, accountID: props.appState.accountID, accountName: props.appState.accountName });

    }

    async function onCancel(event) {
        event.preventDefault();

        setIsUpdating(false);
        setFormData({ ...emptyState, accountID: props.appState.accountID, accountName: props.appState.accountName });
    }

    async function setSolutionName(solutionName) {
        setFormData({ ...formData, solutionName: solutionName });
    }

    function validate() {

        if (formData.accountID === undefined) { return false; }
        if (formData.accountName === undefined) { return false; }
        if (formData.solutionName === undefined) { return false; }
        if (formData.utcStartTime === undefined) { return false; }
        if (formData.utcEndTime === undefined) { return false; }

        if (formData.accountName.length < 1) { return false; }
        if (formData.solutionName.length < 1) { return false; }

        if (formData.utcStartTime.isSameOrAfter(formData.utcEndTime)) { return false; }
        if (formData.utcEndTime.isAfter(moment().startOf('day'))) { return false; }

        return true;
    }

    const formItemLayout =
        {
            labelCol: { span: 4 },
            wrapperCol: { span: 14 },
        };

    const buttonItemLayout = {
        wrapperCol: { span: 14, offset: 4 },
    };

    async function setAccountDetails(id, name) {
        setFormData({ ...formData, accountID: id, accountName: name });
    }

    const form = [];

    function disallowFuture(selected) {
        if (selected.isSameOrAfter(moment().startOf('day'))) {
            return true;
        }
        return false;
    }

    function disallowBackwards(selected) {
        if (selected.isSameOrAfter(moment().startOf('day'))) {
            return true;
        }
        if (selected.isBefore(formData.utcStartTime)) {
            return true;
        }
        return false;
    }

    form.push(
        <Form.Item key="acc" label="Choose account">
            <AccountChooser accountID={formData.accountID} setAccountDetails={setAccountDetails} reports={true}/>
        </Form.Item>
    );

    form.push(
        <Form.Item key="sol" label="Select solution">
            <SolutionChooser
                solutionName={formData.solutionName}
                setSolutionName={setSolutionName}
            />
        </Form.Item>
    );

    form.push(
        <Form.Item key="start" label="Start Date">
            <DatePicker
                allowClear={false}
                name="utcStartTime"
                onChange={(date) => {setFormData({ ...formData, utcStartTime: date });}}
                value={formData.utcStartTime}
                disabledDate={disallowFuture}
            />
        </Form.Item>
    );

    form.push(
        <Form.Item key="end" label="End Date">
            <DatePicker
                allowClear={false}
                name="utcEndTime"
                onChange={(date) => {setFormData({ ...formData, utcEndTime: date });}}
                value={formData.utcEndTime}
                disabledDate={disallowBackwards}
            />
        </Form.Item>
    );

    form.push(
        <Form.Item key="buttons" {...buttonItemLayout}>
            <ButtonGroup>
                <Button onClick={onCancel}>Cancel</Button>
                <Button type='primary' htmlType='submit' disabled={!validate()} loading={isUpdating}>
                    Create Report
                </Button>
            </ButtonGroup>
        </Form.Item>
    );


    return (
        <div>
            <h3>Create New Report</h3>
            <Form onSubmit={onSubmit} {...formItemLayout} className='edit-entitlement-form'>
                {form}
            </Form>
        </div>
    );
}

ReportCreate.propTypes = {
    appState: PropTypes.shape({
        accountID:  PropTypes.string,
        accountName: PropTypes.string,
        userRole: PropTypes.string,
    }),
    readOnly: PropTypes.bool
};