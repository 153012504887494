import React from 'react';

class Triggers {
    constructor() {
        this.listeners = [];
    }

    addListener(trigger, callback) {
        this.removeListener(callback);
        this.listeners.push({ trigger: trigger, callback: callback });
    }

    removeListener(callback) {
        const index = this.listeners.findIndex((item) => item.callback === callback);
        if (index !== -1) {
            this.listeners.splice(index, 1);
        }
    }

    raise(trigger) {
        for (let listener in this.listeners) {

            if (this.listeners[listener].trigger === trigger) {
                this.listeners[listener].callback();
            }
        } 
    }
}

const TriggerContext = React.createContext(null);

export {
    Triggers,
    TriggerContext,
};

export default Triggers;