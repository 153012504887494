import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Form, Input, Table } from 'antd';
import PropTypes from 'prop-types';

import { ApiContext } from '../services/HubApi';
import { TriggerContext } from '../services/Triggers';

import AccountChooser from './AccountChooser';
import moment from 'moment';

export default function ReportMgmt(props) {
    const api = useContext(ApiContext);
    const triggers = useContext(TriggerContext);

    const [accountID, setAccountID] = useState(props.appState.accountID);

    const [reports, setReports] = useState([]);
    const [isLoading, setLoading] = useState(false);

    const emptyState = { valid: false };
    const [viewReport, setViewReport] = useState(emptyState);

    const onLoad = useCallback(async () => {
        async function loadReportIds() {
            try {
                const reportArray = await api.getReports(accountID);

                const reportObjects = reportArray.reduce((arr, obj) => {
                    arr.push({ key: obj, loading: true });

                    return arr;
                }, []);

                return reportObjects;
            }
            catch (error) {
                console.error(error);
                //alert(error);
            }
        }

        async function loadReportData(id) {
            try {
                let reportData = await api.getReport(accountID, id);
                reportData['key'] = id;
                reportData['loading'] = false;

                return reportData;
            }
            catch (error) {
                console.error(error);
                //alert(error);
            }
        }

        setLoading(true);

        let reportIds = await loadReportIds();

        let newReports = [];

        for (let i = 0; i < (reportIds ? reportIds.length : 0); ++i) {
            const reportId = reportIds[i].key;
            newReports[i] = await loadReportData(reportId);
        }

        setReports(newReports);
        setLoading(false);
    }, [api, accountID]);

    useEffect(() => {
        triggers.addListener('New Report', onLoad);
        onLoad();
    }, [onLoad, triggers]);

    useEffect(() => {
        return () => {
            //console.log("ReportMgmt cleanup")
        };
    }, []);

    async function onViewReport(id, event) {
        event.preventDefault();

        for (let i = 0; i < reports.length; ++i) {
            if (reports[i].key === id) {
                setViewReport({ ...reports[i], valid: true });
            }
        }
    }

    async function setAccountDetails(id) {
        setAccountID(id);
    }

    const page = [];

    if (viewReport.valid) { // Report detail view

        const details = [];
        const detailsData = [
            { label: 'Account', data: 'accountName' },
            { label: 'Solution', data: 'solutionName' },
            { label: 'Report Date', data: 'utcGeneratedTime' },
            { label: 'Period Start', data: 'utcStartTime' },
            { label: 'Period End', data: 'utcEndTime' },
        ];

        for (let i = 0; i < detailsData.length; ++i) {
            const d = detailsData[i];
            details.push(
                <Form.Item key={i} label={d.label}>
                    <Input
                        name={d.data}
                        disabled={true}
                        value={viewReport[d.data]}
                    />
                </Form.Item>
            );
        }

        const formItemLayout =
        {
            labelCol: { span: 4 },
            wrapperCol: { span: 14 },
        };

        page.push(
            <Form key="details" {...formItemLayout} className='edit-user-form'>
                {details}
            </Form>
        );

        const renderMetrics = (...[, row, ]) => {

            const metricsData = [];

            for (var key in row.metrics) {
                metricsData.push({ 'key': key, 'value': row.metrics[key] });
            }

            const columns = [
                { title: 'Name', width: '50%', dataIndex: 'key', key: 'key' },
                { title: 'Value', width: '50%', dataIndex: 'value', key: 'value' },
            ];

            return (
                <Table
                    key="table"
                    bordered
                    dataSource={metricsData}
                    loading={isLoading}
                    columns={columns}
                    showHeader={false}
                    pagination={false} 
                />
            );
        };

        const columns = [
            { title: 'Reference', dataIndex: 'reference', key: 'reference' },
            { title: 'Type', dataIndex: 'type', key: 'type' },
            { title: 'ID', dataIndex: 'id', key: 'id' },
            { title: 'Quota', dataIndex: 'quota', key: 'quota' },
            { title: 'Metrics', dataIndex: 'metrics', key: 'metrics', render: renderMetrics },
        ];

        page.push(
            <Table
                key="table"
                bordered
                dataSource={viewReport.items}
                loading={isLoading}
                rowKey={record => record.id + record.quota}
                columns={columns}
            />
        );

        page.push(
            <span key="buttons">
                <Button key="cancel" onClick={() => {
                    setViewReport(emptyState);
                }}>Back to List</Button>
                <Button href={api.getApiUrl() + 'accounts/' + accountID + '/reports/' + viewReport.reportID + '?format=csv'} >Export Report to CSV</Button>
            </span>
        );

    } else { // List view

        if (['mkOps', 'mkSales'].includes(props.appState.userRole)) {
            const formItemLayout =
                {
                    labelCol: { span: 4 },
                    wrapperCol: { span: 14 },
                };

            page.push(
                <Form {...formItemLayout} key="acc" className='choose-account-form'>
                    <Form.Item label="Choose account">
                        <AccountChooser accountID={accountID} setAccountDetails={setAccountDetails}/>
                    </Form.Item>
                </Form>
            );
        }

        const renderPeriod = (...[, row, ]) => {
            const b = moment(row['utcStartTime']);
            const a = moment(row['utcEndTime']).add(1, 's');

            var duration = b.format() + ' - ' + a.format();

            if (a.diff(b, 'months') > 0) {
                duration = a.diff(b, 'months') + ' months';
            }
            else if (a.diff(b, 'days') > 0) {
                duration = a.diff(b, 'days') + ' days';
            }
            else if (a.diff(b, 'hours') > 0) {
                duration = a.diff(b, 'hours') + '  hours';
            }
            else {
                duration = a.diff(b, 'minutes') + ' minutes';
            }
            
            return {
                children: duration
            };
        };

        const renderAction = (...[, row, ]) => {

            return (
                <Button onClick={(event) => {
                    onViewReport(row.key, event);
                }}> Details </Button>
            );
        };

        const columns = [
            { title: 'Solution',    dataIndex: 'solutionName',     key: 'solutionName' },
            { title: 'Account',     dataIndex: 'accountName',      key: 'accountName' },
            { title: 'Report Date', dataIndex: 'utcGeneratedTime', key: 'utcGeneratedTime' },
            { title: 'Period',      key: 'period', render: renderPeriod },
            { title: 'Action',      key: 'action', render: renderAction }
        ];

        page.push(
            <Table
                key="table"
                bordered
                dataSource={reports}
                loading={isLoading}
                rowKey={record => record.key}
                columns={columns}
            />
        );
    }
    return (
        <>
            {page}
        </>
    );
}

ReportMgmt.propTypes = {
    appState: PropTypes.shape({
        accountID:  PropTypes.string,
        accountName: PropTypes.string,
        userRole: PropTypes.string,
    }),
    readOnly: PropTypes.bool
};